.footer {
  // position: sticky;
  left: 0;
  bottom: 0;
  width: 100%;
  margin-top: 64px;

  .footer__icon-group {
    padding:0px;
    svg {
      width: 24px;
      height: 24px;
      path {
        fill: $medium-grey;
      }
    }
  }
}