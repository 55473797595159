.game-details {
  margin: auto;

  .game-details__player {
    height: 100%;
    max-width: 100%;
  }

  .game-details__player .slick-slider {
    height: inherit !important;
    position: relative;
  }

  .description {
    white-space: pre-line;
  }

  .game-details__player .slick-slide img {
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .game-details__player .slick-slide iframe {
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }

  .game-details__player .slick-track {
    background-color: black;
  }

  .slick-track {
    display: flex !important;
  }

  .slick-slide {
    height: inherit !important;
  }

  .game-details__carousel .slick-slide {
    height: 175px !important;
    position: relative;
  }

  .game-details__carousel .slick-current {
    z-index: 5;
    border: solid 2px white;
  }

  .game-details__carousel .slick-slide {
    > div {
      //margin: 0px 7px;
      overflow: hidden;
      position: relative;
      > div {
        height: inherit !important;
        width: inherit !important;
      }
    }
    img {
      max-height: 100%;
      max-width: 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    iframe {
      pointer-events: none;
    }
  }

  .game-details__play-button {
    padding: 15px 12px;
  }

  .game-details__store-buttons .store-buttons {
    margin-top: 16px;
  }

  .game-details__vault-button {
    padding: 15px 12px;
    max-width: 100%;
    flex-basis: 100%;
  }

  .game-details__game-info-card {
    padding: 12px;
  }

  .game-details__game-info-card .details-text {
    margin-top: 40px;
  }
}
