.footer-action-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 104px;
  opacity: 0.95;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19), 0 8px 17px 0 rgba(0, 0, 0, 0.2);
  background-color: $off-black;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 135px;
}
