.conversion-card {
  @include border(1px, $G8);
  div.outlined {
    @include border(1px, $deInputBorder);
    background-color: $deInput;
    border-radius:4px;
    padding:0px 0px 0px 16px;
    min-height:58px;
    >div{
      padding: 11px 0px 5px 0px;
      >img {
        margin: 6px auto!important;
        display: block;
      }
    }
    div:nth-child(2){
      border-left:solid 1px $deInputBorder;
      >div{    
        margin: auto;
        display: block;
        padding: 0px 10% 0px 16%;
      }
    }
    input, label{
      text-align:right;
      font-size:16px;
      font-weight: 900;
      text-align: left;
    }
    .mt--0{
      //fix mui overriding this
      margin-top:0px;
    }

    label {
      margin-top:-12px;
    }
  }
  .pubcut {
    min-height: 20px;
  }
  .tablike {
    padding:5px 15px;
  }
  .h4sub {
    font-size: 1.714rem;
    font-weight: 300;
    line-height: 1.38;
  }
}