.modal-570 {
  background: $off-black;
  padding: 76px;
  box-sizing: border-box;
}

.intro-modal {
  max-width: 95%;
  margin-top:10px;
  margin:auto;
  @media only screen and (min-width: 1280px) {
    max-width: 1280px;
    margin-top:100px;
  }
  .intro-modal-paper{
    width:100%;
    &>*{
      padding:0px 60px 60px 60px;
      background-image:url("https://img.tapplatform.io/stat/Backgrounds/PlayerExplainerPart3.png");
      background-color: rgba(20, 20, 20, 0.9);
      background-blend-mode: multiply;
      background-size: 80%;
      background-position-x: center;
      background-repeat: no-repeat;
    }
    button.next{
      min-width: 120px;
      display: block;
      margin: 24px auto;
    }
  }
}

.wallet-modal {
  .wallet-modal-paper{
    padding:20px;
  }
  .wallet__taplogo{
    height:20px;
    width:auto;
  }
}