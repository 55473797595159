.settings-page {
  @include medium {
    display: grid;
  }
  grid-template-columns: 273px auto;
  grid-column-gap: 24px;
  
  .settings-page__menu {
    border: 1px solid $G8;
    align-self: start;  
  }

  .settings-page__menu-list {
    width: 100%;
    padding-top: 0px;
    padding-bottom: 0px;

    .settings-page__menu__icon {
      * {
        fill: $medium-grey;
      }
      &--active {
        * {
          fill: $white;
        }
      }
    }

    .settings-page__menu__text {
      color: $medium-grey;
      &--active {
        color: $white;
      }
    }
    li {
      padding: 25px;
      color: $light-grey;
    }
  }

  $settings-content-padding-large: 40px;
  $settings-content-padding-small: 15px;
  .settings-page__content {
    display: flex;
    flex: 1 1 0;
    min-width: 200px;
    padding: $settings-content-padding-small;
    @include large {
      padding: $settings-content-padding-large;
    }

    & > div {
      width: 100%;
    }
  }

  .settings-page__content-divider {
    border-top: 1px solid $lightSectionLine;
    margin-top: 40px;
    margin-bottom: 40px;
    width: calc(100% + (#{$settings-content-padding-small} * 2));
    margin-left: -$settings-content-padding-small;
    @include large {
      width: calc(100% + (#{$settings-content-padding-large} * 2));
      margin-left: -$settings-content-padding-large;
    }

    &.content-divider--small {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  .settings-page__avatar-img {
    width: 148px;
    height: 148px;
    overflow: hidden;
    border-radius: 50%;
    img {
      width: 100%;
      min-height: 100%;
    }
  }
}
