.detailed-vault-card {
  padding: 25px 24px;
  img {
    height: 92px;
    margin: auto;
    display: block;
  }
}

.vault-card {
  padding: 12px;
  img {
    height: 64px;
    margin: auto;
    display: block;
  }
  p {
    line-height: 48px;
    margin-top: 0px;
  }
}
