.dashboard__no-games {
    max-width: 372px;
    margin:220px auto;
}

.dashboard__games {
    .pub-addgame {
        height:50px;
        width:50px;
        margin: auto;
        display: block;
        border-width: 2px;
    }
}