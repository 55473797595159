@mixin large {
  @media (min-width: #{$breakpoint-lg}) {
    @content;
  }
}

@mixin medium {
  @media (min-width: #{$breakpoint-md}) {
    @content;
  }
}

@mixin mediumsmall {
  @media (min-width: #{$breakpoint-ms}) {
    @content;
  }
}

@mixin small {
  @media (min-width: #{$breakpoint-sm}) {
    @content;
  }
}

@mixin xsmall {
  @media (max-width: #{$breakpoint-sm}) {
    @content;
  }
}

@mixin upToMedium {
  @media (min-width: #{$breakpoint-xs}) and (max-width: #{$breakpoint-md}) {
    @content;
  }
}

.mobile-menu {
  display:block;
  @include mediumsmall {
    display:none;
  }
}