$textColors: (
  lePrimary: $lePrimary,
  leSecondary: $leSecondary,
  leDisabled: $leDisabled,
  redBase: $redBase, // alert red
  yellowBase: $yellowBase,
);

@mixin iconColor($color: null) {
  color: $color;
  fill: $color;
}

@each $key, $value in $textColors {
  .ic--#{$key} {
    @include iconColor($color: $value);
  }
}