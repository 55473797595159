@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.load-spinner {
  position: relative;
  &:before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 2px solid white;
    border-top-color: transparent;
    animation: spinner 0.6s linear infinite;
  }
}

.load-spinner.load-spinner--small {
  width: 19px;
  height: 19px;
  display: inline-block;
}

.load-spinner.load-spinner--large {
  width: 60px;
  height: 60px;
  &:before {
    border-width: 4px;
  }
}

.load-spinner.load-spinner--xlarge {
  width: 144px;
  height: 144px;
  &:before {
    border-width: 6px;
  }
  &.load-spinner--responsive {
    width: 132px;
    height: 132px;
  }
  &.load-spinner--centered {
    margin: auto;
    margin-top: 180px;
  }
}

.body--container {
  > .load-spinner {
    margin: 100px auto;
  }
}
