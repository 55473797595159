.add-game {
  max-width: 372px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top:128px;
  &.details {
    max-width:966px;
  }
  &.vault-info {
    max-width: 460px;
  }

  .add-game__economy-card {
    padding: 24px;
    border-radius: 8px;
    border: solid 2px $dark-grey;
    cursor: pointer;
    &:hover{
      border: solid 2px $tap-primary;
    }
  }
}