.login {
  
  &::before {
    content: '';
    background-image: url(https://img.tapplatform.io/stat/StuntRunnerbackground.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    opacity: 0.25;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .login__form {
    width: 548px;
    padding: 64px;
    margin: 128px auto 0px;
    position: relative;
    border-radius: 8px;
  }

  .login__welcome-back {
    font-size: 36px;
    font-weight: 900;
  }

  .login__caption {
    font-size: 18px;
    font-weight: 300;
  }
}

.login-modal-paper {
  margin-top:48px;
  .header {
    display:none;
  }
  .login-modal-form {
    max-width: 100%;
    width:672px;
    overflow-y: auto;
    padding:24px 7%;
    margin:auto;
  }
  .forgot-password__button {
    min-height: 0px;
    font-size:.8571rem;
  }
}


@media only screen and (max-width: 768px) {
  .login-modal-paper {
    .header {
      display:block;
      border-bottom: 2px solid $dsMin2;
      height:48px;
      padding:8px 28px;
      p {
        margin-top: 0px;
      }
    }
    max-height: 100%;
    height:100%;
    margin-top:0px;
    .login-modal-form {
      width:100%;
    }
  }
}