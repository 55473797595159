.body--container.vaults, .body--container.vault {
  max-width:100%;
  padding:0px;
  .vault-details {
    margin-top:64px;
    max-width: 594px;
    width:90%;
    margin:auto;
  }

  .vault__divider {
    background-color: $dark-grey;
    margin: 8px 0 24px 0;
  }

  .vault-card  {
    width: 100%;
  }
}

.allvaults {
  margin-top:64px
}