.market {

  .market__subheader {
    margin-top: 11px;
  }

  .market__content {
    margin-top: 12px;
  }

  .tap-banner {
    position: absolute;
    width: 100%;
    bottom: 0px;
  }

  .platform-icons {
    bottom: 24px;
    position: absolute;
    right: 24px;
  }

}

.challenges {
  .steam-connect {
    bottom: 24px;
    position: absolute;
    left:24px;
  }

  a.steam-card:hover, a.game-card:hover {
    text-decoration:none;
  }

  .steam-connect svg{
    height:20px;
    width:20px;
    margin-right:10px;
    &.steam-icon {
      g {
        fill:white;
      }
    }
  }
}

.body--container.challenge{
  max-width:100%;
  padding:0px;
  .bg-holder{  
    background-repeat: no-repeat;
    background-size: contain;
    background-color:#1B2738;
  }
  .gradient-holder{
    background-image: linear-gradient(to bottom, rgba(0, 0, 0 , 0), #1B2738);
  }
  .achievement__details {
    margin-top:0px;
    padding-top:24px;
    @include max-widths;
    .achievements {
      .achievement-panel2{
        border-left: 1px solid #45464E;
      }
      .achievement-completed {
        width:33px;
        height:auto;
        margin:auto;
        display:block;
        g {
          fill:$greenBase;
        }
      }
    }
  }
}