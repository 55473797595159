.register {
  max-width: 469px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 80px;
  position: relative;
  &::before{
    background-image: url(https://img.tapplatform.io/stat/register.png);
    height: 130%;
    content: "";
    width: 140%;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    background-size: cover;
    right: 10%;
    opacity: .25;
    top: -5%;
  }
}

.register.pub-register {
  &.TOS {
    max-width: 792px;
    width: 100%;
    margin-top: 0px; 
  }
  &::before{
    background-image:none;
    display:none;
  }
  width:372px;
  margin-bottom:120px;
}

.img-upload {
  width:auto;
  max-width:100%;
  height:124px;
  margin:auto;
  display:block;
}

.imglabel{
  cursor:pointer;
}

.complete-account-info {
  max-width:792px;
  margin:auto;
}

.TOS {
  max-width: 792px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 80px; 
  
  .register__TOS-title {
    padding: 16px 48px;
    max-height: 720px;
    overflow: auto;
  }
}

.register-modal-paper {
  margin-top:48px;
  max-height: calc(100% - 24px);
  min-height: 240px;
  overflow-y: auto;
  .header {
    display:none;
  }
  @media only screen and (max-width: 768px) {
    .header {
      display:block;
      border-bottom: 2px solid $dsMin2;
      height:48px;
      padding:8px 28px;
      p {
        margin-top: 0px;
      }
    }
    max-height: 100%;
    height:100%;
    margin-top:0px;
  }
  .register-modal-form {
    max-width: 100%;
    width:900px;
    padding:24px 5%;
  }
}