.game-page {

  .game-page__menu {
    border: 1px solid $G8;
    flex: 0 0 273px;
    margin-right: 24px;
  }

  .game-page__menu-list {
    width: 100%;
    padding-top: 0px;
    padding-bottom: 0px;

    .game-page__menu__icon {
      * {
        fill: $medium-grey;
      }
      &--active {
        * {
          fill: $white;
        }
      }
    }

    .game-page__menu__text {
      color: $medium-grey;
      &--active {
        color: $white;
      }
    }
    li {
      padding: 25px;
      color: $light-grey;
    }
  }

  $game-content-padding-large: 56px 99px;
  $game-content-padding-small: 15px 25px;
  .game-page__content {
    display: flex;
    flex: 1 1 0;
    min-width: 200px;
  }

  .game-card__content {
    padding: $game-content-padding-small;
    @include large {
      padding: $game-content-padding-large;
    }
  }

  .teaser-card__content__game-card{
    max-width: 570px;
    margin:auto;
  }

  .game-page__content-divider {
    border-top: 1px solid $lightSectionLine;
    margin-top: 22px;
    margin-bottom: 22px;
    width: 100%;
    @include large {
      width: 100% ;
    }
  }

  .game-page__content {
    display:block !important;
  }

  .currency__content {
    img  {
      background-color: rgba(23, 23, 26, 0.3);
    }
  }

  .promo__content, 
  .sign-in-promo{
    width:570px;

    .promo__content__banner {
      padding: 94px 0;
      position: relative;
      height:360px;
      margin: 64px+71px 0px;
    }
    
    .promo__content__banner-info {
      max-width: 420px;

      & h1, span {
        margin-bottom: .2em;
      }
    }

    .sign-in-promo__content-img {
      border: 3px solid $white;
      height: 287px;
    }

    .teaser-card__content__game-card {
      max-width: 570px;
      min-height: 513px;
      margin: 35px auto 0px;
      border: solid 1px $dark-grey;
      border-radius: 8px;
    }
  }
}
