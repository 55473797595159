//Sizing
.fullWidth {
  width: 100% !important;
}

.fullHeight {
  height:100% !important;
}

.width-440 {
  width:440px;
}

.hfc {
  height:fit-content;
}

.pagesize{
  @include xsmall {
    max-width: 375px !important;
  }
  @include small {
    max-width: 472px !important;
  }
  @include medium {
    max-width: 716px !important;
  }
  @include large {
    max-width: 1164px !important;
  }
}

.wfc{
  width:fit-content;
}

// Spacing

.jc--sb {
  justify-content: space-between;
}

//Positioning
.fixed {
  position: fixed;
  &.fixed-bc {
    bottom: 0px;
    margin: auto;
  }
}

.float-left, .float--left{
  float:left;
}

.float-right, .float--right{
  float:right;
}

.display-inline, 
.display--inline {
  display:inline;
}

.display-block, .display--block{
  display:block !important;
}

.display--inline-block {
  display: inline-block;
}

.display-none, .display--none{
  display:none;
}

.clr--r{
  clear:right;
}

.clr--l{
  clear:left;
}

.clr--b{
  clear: both;
}

.pos--abs{
  position: absolute;
}

.pos--rel{
  position: relative;
}

//aligning
.va--middle {
  vertical-align: middle;
}
.va--bottom {
  vertical-align: bottom;
}

//Border
@mixin border($width, $color) {
  border: $width solid $color;
}

.border-default {
  @include border(1px, $G8);
}

.border-g5 {
  @include border(1px, $medium-grey);
}

.border-leSecondary {
  @include border(1px, $leSecondary);
}

.border4-greenBase {
  @include border(4px, $greenBase);
}

.border-white {
  @include border(1px, #fff);
}

.border4-white {
  @include border(1px, #fff);
}

.border4-grey {
  @include border(1px, #777);
}


.border-radius-6{
  border-radius:6px;
}

.border-radius-16{
  border-radius:16px;
}
.br--circle {
  border-radius: 1000px;
}

// text style overrides

.bold {
  font-weight: 600 !important;
}

.semi-bold {
  font-weight: 500 !important;
}

.normal {
  font-weight: 400 !important;
  opacity: 1;
}

.text--centered {
  text-align: center !important;
}

.text--left {
  text-align: left !important;
}

.text--right{
  text-align: right !important;
}

.font-size-14{
  font-size: 14px;
}

.font-size-16{
  font-size: 16px;
}

.font-size-18{
  font-size: 18px;
}

.td--underline{
  text-decoration: underline;
}

.text--ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1 0 auto; 
  * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.lh--h2 {
  line-height: 4.49199rem;
}

// Icons
.ico-36-wrapper{
  width:36px;
  height: 100%;
  position:relative;
  img {
    width:100%;
    height:auto;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
  }
}

.img-wrapper{
  position:relative;
  img {
    width:100%;
    height:auto;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
  }
}

.img-wrapper-rel{
  position:relative;
  img {
    width:100%;
    height:auto;
    position: relative;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
  }
}

.img-container {
  img {
    max-width: 100%;
  }
}

// buttons
.btn--large {
  @include large {
    min-width: 273px;
    min-height: 56px;
  }
}

.cursor--pointer {
  cursor: pointer;
}

//badges
.badge {
  display: block;
  position: relative;
  margin: auto;
  height: 80px;
  max-width: 80px;
  img {
    &:nth-child(1){
      z-index:10;
    }
    &:nth-child(2){
      z-index:9;
      width:50%;
      height:auto;
      max-height:50%;
      border-radius: 0%;
      top: 50%;
    }
    height:100%;
    width:auto;
    position: absolute;
    margin: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}


//flex stuff
.flex{
  display: flex;
  .flex-1{
    flex: 1;
  }
}
