@import url("https://use.typekit.net/vqv6fuo.css");

html {
  font-family: neue-haas-unica, Helvetica, Arial, sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-color: darkgrey inherit;
  scrollbar-width: thin;
}

body {
  color: #ffffff;
}

.flex-base {
  display: flex;
}

.body--container {
  flex-grow: 1;
  max-width: 100%;
}

a {
  color: inherit;
  text-decoration: inherit;
}

ul {
  padding-inline-start: 20px;
}

.breakline {
  width: 100%;
  border-bottom: solid 2px #454545;
  margin-top: 8px;
}

.hidden {
  height: 0px;
  width: 0px;
  display: none;
}

.label {
  cursor: pointer;
}

.tap-logo {
  display: block;
  margin-right: auto;
  margin-left: auto;
  height: 48px;
  width: 100%;
}

.star {
  vertical-align: bottom;
}

.tooltip {
  cursor: pointer;
  .tooltip--hint {
    background-color: #454545;
    width: 20px;
    height: 20px;
    display: inline-block;
    border-radius: 50px;
    margin-left: 4px;
    margin-right: 4px;
    padding-top: 1px;
    padding-right: 1px;
    text-align: center;
  }
  &:hover {
    .tooltip--hint {
      background-color: lighten(#454545, 25);
      color: lighten(#999ba3, 25);
    }
  }
  &.light {
    .tooltip--hint {
      background-color: lighten(#454545, 25);
      color: lighten(#999ba3, 25);
    }
    &:hover {
      .tooltip--hint {
        background-color: lighten(#454545, 50);
        color: #fff;
      }
    }
  }
}

.fillbar {
  width: 100%;
  height: 5px;
  background: $dsMin2;
  border-radius: 25px;
  overflow: hidden;
  .progress {
    height: 100%;
  }
}

.checkbox {
  position: relative;
  float: left;
  .checkbox-required {
    top: 42px;
    left: 4px;
    margin-top: 0px;
    position: absolute;
    color: $tap-alert;
  }
}

.checkbox-label {
  flex: 1;
  float: left;
}

//browser normalization

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

input[type="password"] {
  letter-spacing: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}

::-webkit-scrollbar-track {
  background-color: inherit;
}

::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

/* change style autofill */
/*input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 0;
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
  background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(0,174,255,0.04) 50%,rgba(255,255,255,0) 51%,rgba(0,174,255,0.03) 100%);
}*/
