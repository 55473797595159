@mixin max-widths {
  width: 100%;
  @include xsmall {
    padding-right: 16px;
    padding-left: 16px;
  }
  @include small {
    padding-right: 24px;
    padding-left: 24px;
  }
  @include medium {
    padding-right: 32px;
    padding-left: 32px;
  }
  @include large {
    padding-right: 40px;
    padding-left: 40px;
    max-width: $breakpoint-xl;
    margin-right: auto;
    margin-left: auto;
  }
}

.max-widths {
  @include max-widths;
}

.fullwidth {
  width:100%;
}

.width-max-content{
  width:max-content;
}