//
//  WARNING
// Everything beyond this is depricated!!
$tap-primary: #aa49ef; // depricated !
$tap-secondary: #ffc024; // depricated !
$tap-success: #59c875; // depricated !
$tap-success-dark: #37c65c; // depricated !
$tap-alert: #f36861; // depricated !
$tap-alert-dark: #f7534a; // depricated !
$metamask: #ff9900; // depricated !
$white: #ffffff; // W - depricated
$light-grey: #d9d9d9;// G1 - depricated
$medium-grey: #8f8f8f; // G5 - depricated
$bwSecondary: #a6a6a6;
$dark-grey: #454545; // G7 - depricated
$G8: #333333; // depricated
$off-black: #242322; // G9 - depricated
$black: #141414; // G10 (This is not black) - depricated
//
// ---
//
// ICON FONT COLOURS
// Depricated - see style/utils/colors 
.text__black {
  color: $black;

  span, svg {
    color: $black;
  }
}

// Depricated - see style/utils/colors 
.text__white {
  color: $white;

  span, svg {
    color: $white;
  }
}

// Depricated - see style/utils/colors 
.text__light-grey {
  color: $light-grey;

  span, svg {
    color: $light-grey;
  }
}

// Depricated - see style/utils/colors 
.text__medium-grey {
  color: $medium-grey;
  
  span, svg {
    color: $medium-grey;
  }
}
.text__secondary {
  color: $bwSecondary;
}

// Depricated - see style/utils/colors 
.text__purple {
  color: $tap-primary;

  span, svg {
    color: $black;
  }
}

// Depricated - see style/utils/colors 
.text__yellow {
  color: $tap-secondary;

  span, svg {
    color: $tap-secondary;
  }
}

// Depricated - see style/utils/colors 
.text__green {
  color: $tap-success;

  span, svg {
    color: $tap-success;
  }
}

// Depricated - see style/utils/colors 
.text__red {
  color: $tap-alert;

  span, svg {
    color: $tap-alert;
  }
}

// Depricated - see style/utils/colors 
.btn-green {
  background-color: $tap-success-dark;
}

// Depricated - see style/utils/colors 
.btn-red {
  background-color: $tap-alert-dark;
}

// Depricated - see style/utils/colors 
.btn-orange {
  background-color: $metamask;
  color: $white;
}

// Depricated - see style/utils/colors 
.MuiButton-contained-188.MuiButton-disabled-195.btn-green {
  background-color: $tap-success-dark;
}
