.email_verification {
  .email__header {
    margin-top: 164px;
  }
  .email__reset-button {
    margin-top: 124px;
  }
  .btn-linkfix {
    width:fit-content;
    display:block;
    padding:8px 16px;
  }
  .btn-play {
    min-width:250px;
    display:block;
  }
}