// Padding// depricated
.padding-16 {
  padding: 16px;
}
// depricated
.padding-24 {
  padding: 24px;
}

//  Margin// depricated
.margin-auto {
  margin: auto;
}
// Left// depricated
.margin-left-16 {
  margin-left: 16px;
}
// depricated
.margin-left-24 {
  margin-left: 24px;
}
// depricated
// Right
.margin-right-4 {
  margin-right:4px;
}
// depricated
// Bottom
.margin-bottom-8 {
  margin-bottom: 8px;
}
// depricated
.margin-bottom-16 {
  margin-bottom: 16px;
}
// depricated
.margin-bottom-24 {
  margin-bottom: 24px;
}
// depricated
.margin-bottom-32 {
  margin-bottom: 32px;
}
// depricated
.margin-bottom-188 {
  margin-bottom: 188px;
}