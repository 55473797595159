.nav {
  background-color: $dsMin4;
  position:relative;
  box-shadow: none;
  border-bottom: solid 2px $dsMin2;
  //max-width: 100%;
  padding: 0px 10px;
  @include small {
    padding: 0px 10px;
  }

  .nonmobile {
    display:none;
    @include mediumsmall {
      display: block;
    }
  }
  .mobile {
    display:block;
    @include mediumsmall {
      display: none;
    }
  }
  
  .nav__content {
    z-index: 8;
    padding-bottom: 4px;
    padding-top: 2px;
    
    .nav__content__menu-left {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: left;
      // @include mediumsmall {
      //   justify-content: left;
      // }
      flex:1;
    }

    .nav__content__menu-middle {
      svg {
        height: 50px;
        width: 90px;
      }
    }

    .nav__content__action-button {
      align-content: center;
      display: grid;
    }

    .nav__content__right-nav--desktop {
      display: none;
      @include mediumsmall {
        display: flex;
      }
      flex:1;
      .fitter {
        display: flex;
        justify-content:  flex-end;
        width: 100%;
      }
    }

    .nav__content__right-nav--mobile {
      display: none;
      //@include medium {
      //  display: none;
      //}
    }

    .nav__content__badge {
      display: none;
      width: 58px;
      padding: 0px;
      margin-top:4px;
      position:relative;
      border-left: 2px solid $dePrimary;
      
      img {
        max-width:100%;
        display: block;
      }

      @include mediumsmall {
        display: block;
      }
    }

    .badge {
      height:60px;
      img {
        &:nth-child(1){
          z-index:10;
        }
        &:nth-child(2){
          z-index:9;
          width:50%;
          height:auto;
          max-height:50%;
          border-radius: 0%;
          top: 50%;
        }
        width:100%;
        position: absolute;
        margin: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .nav__content__level {
      background-color: $dsMin1;
      border-radius: 10px;
      display: flex;
      align-items: center;
      padding: 0px 20px 0px 40px;
      margin: 10px 0px;
    }

    .nav__content__tabs {
      > div > div {
        border-bottom:none;
      }
      @include small {
        display: block;
      }
      > div > div {
        border-bottom:none;
      }
    }

    .nav__content__avatar {
      display: none;
      width: 30px;
      margin:8px 8px;
      position:relative;
      
      img {
        width:100%;
        border-radius: 50%;
        display: block;
        position: absolute;
      }

      @include mediumsmall {
        display: flex;
        align-items: center;
      }
    }

    .nav__content__user {
      display: flex;
      align-items: center;
      &:hover {
        background: $dark-grey;
        cursor: pointer;
      }
    }
    
    .nav__content__user-subinfo {
      display: flex;
      justify-content: flex-end;
    }

    .nav__content__menu-button {
      align-items: center;
      display: flex;
      padding:0px 10px;
    }
  }
}

.nav-notif {
  padding:4px 16px;
  text-align: center;
  width: 100%;
  display:block;
  color:#fff;
  position: relative;
}

.subnav {
  padding:32px 10px;
  a.square, button.square {
    min-width: 80%;
    border-radius: 4px;
  }
  display: none;

  @include mediumsmall {
    display: flex;
  }
}