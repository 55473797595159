.stakecard{
  margin:40px 20px 20px 20px;
  overflow: visible;

  .cardcontent{
    button{
      box-shadow: 0 6px #10067c44;
      margin: auto;
      display: block;
    }
  }

  &.small {
    &.empty{
      .cardcontent{
        min-height: 162px;
        position:relative;
        .wrapper{
          position: absolute;
          width: 100%;
          bottom: 10px;
          left: 0px;
        }
        .button{
          box-shadow: 0 6px #12121244;
        }
      }
    }
  }

  &.purple{
    background: linear-gradient(180deg, #995CD8 0%, #503171 100%);
  }
  &.green{
    background: linear-gradient(180deg, #52B77C 0%, #2B6042 100%);
  }
  &.blue{
    background: linear-gradient(180deg, #2F8FDB 0%, #194C74 100%);
  }
  &.red{
    background: linear-gradient(180deg, #da180b 0%, #940707 100%);
  }
  &.gold{
    background: linear-gradient(180deg, #AD9A5B 0%, #7A6938 75%);
  }
  .cardimg{
    background-size: cover;
    border-radius: 20px;
    min-height: 140px;
    max-width:140px;
    margin:auto;
    top:-40px;
  }
  .cardcontent{
    margin-top:-20px;
    padding-bottom: 8px;
    .stakecard--bottomtextalign {
      position: relative;
      & > p{
        position: absolute;
        bottom: 8px;
      }
    }
  }
  .divider{
    background-color: #c8c8c827;
  }
  .button{
    display: flex;
    max-width: 160px;
    font-size: 18px;
  }

  &.big .cardcontent{
    margin-top:-50px;
    min-height: 360px;
    position: relative;
    .cardcontent-wrapper{
      width:100%;
      position: absolute;
      bottom: 10px;
      left: 0px;
      min-height:170px;
    }
    .btnwrapper{
      min-height:104px;
    }
  }
}

.stakecard--bg:hover{
  background-image: radial-gradient(circle, #7b22a7 0%, rgba(252, 70, 77, 0) 70%);
}

.stakecard--bg.glow{
  background-image: radial-gradient(circle, #A59F22 0%, rgba(252, 70, 77, 0) 70%);
}

.yield-modal-paper {
  padding:40px;
  min-height:480px;
  min-width:740px;
  max-width:100%;

  .yield-lp-icon{
    width:54px;
    height:54px;
  }
  
  .yield-modal-textalign {
    line-height: 59px;
  }

  .yield-dega-text {
    display: inline-block;
    margin: revert;
  }
}

.yield-modal-grid {
  width:80%;
  max-width: 680px;
  min-width: 640px;
  margin:auto;
}