.onboarding-info {
    max-width: 469px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 80px;
    position: relative;
  &::before{
    background-image: url(https://img.tapplatform.io/stat/onboardingInfo.png);
    height: 130%;
    content: "";
    width: 200%;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    background-size: cover;
    right: -50%;
    opacity: .25;
    top: -10%;
  }
}

.onboarding-wallet-connect, .wallet-question {
    max-width: 420px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 80px;
    position: relative;
  &::before{
    background-image: url(https://img.tapplatform.io/stat/Vaultchest.png);
    height: 150%;
    content: "";
    width: 150%;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    background-size: cover;
    right: -20%;
    opacity: .25;
  }
}

.pub-eth-onboarding {
  max-width: 420px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 80px;
  .metamask-browser-icons {
    svg {
      margin-left: 50%;
    }
  }
  .MuiTab-root-102{
    width:50%;
  }
}