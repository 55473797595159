$textColors: (
  lePrimary: $lePrimary,
  leSecondary: $leSecondary,
  leDisabled: $leDisabled,
  redBase: $redBase, // alert red
  greenBase: $greenBase, // alert red
);

@mixin textColor($color: null) {
  color: $color
}

@each $key, $value in $textColors {
  .tc--#{$key} {
    @include textColor($color: $value);
  }
}