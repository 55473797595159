// dark shades
$dsPlus4: #49556d;
$dsPlus3: #d2d3d9;
$dsPlus2: #999ba3;
$dsPlus1: #494b54;
$dsBase0: #363740;
$dsMin1: #2e2e36;
$dsMin2: #1c1c21;
$dsMin3: #17171a;
$dsMin4: #171b24;
// light emphasis colors
$lePrimary: #ffffff;
$leSecondary: #999ba3;
$leDisabled: #3e3f47;
// dark emphasis colors
$dePrimary: #17171a;
$deSecondary: #2e2e36;
$deDisabled: #d2d3d9;
$deInput: #28282f;
$deInputBorder: #222229;
// colors
// primary
$primaryLight: #7377ff;
$primaryBase: #6457f7;
$primaryDark: #5100ff;
// red
$redBase: #f7534a;
$redLight: #f2766f;
// green
$greenBase: #36c77f;
$greenLight: #6bbf95;
// yellow
$yellowBase: #febc0d;
$yellowLight: #fdca4b;

// light section line
$lightSectionLine: rgba(255, 255, 255, 0.08);

$colorArray: (
  // dark shades
  "dsPlus3": $dsPlus3,
  "dsPlus2": #999ba3,
  "dsPlus1": #494b54,
  "dsMin4": #363740,
  "dsMin1": #2e2e36,
  "dsMin2": #1c1c21,
  "dsMin3": #17171a,
  // light emphasis colors
  "lePrimary": #ffffff,
  "leSecondary": #999ba3,
  "leDisabled": #3e3f47,
  // dark emphasis colors
  "dePrimary": #17171a,
  "deSecondary": #2e2e36,
  "deDisabled": #d2d3d9,
  // colors
  // primary
  "primaryLight": #7377ff,
  "primaryBase": #6457f7,
  "primaryDark": #5100ff,
  // red
  "redBase": #f7534a,
  "redLight": #f2766f,
  // green
  "greenBase": #36c77f,
  "greenLight": #6bbf95,
  // yellow
  "yellowBase": #febc0d,
  "yellowLight": #fdca4b,
  "yellowLight2": #f5c64e
);

@each $key, $value in $colorArray {
  .bg--#{$key} {
    background-color: $value;
  }
}

@each $key, $value in $colorArray {
  .color--#{$key} {
    color: $value;
  }
}

.gold-star g {
  fill: $yellowLight;
}

.grey-star g {
  fill: $dsPlus1;
}

.bg--gradient-purple {
  background-image: linear-gradient(to left, #8b7dea, #5347d1);
}

.filter-grayscale100 {
  filter: grayscale(100%);
}
