.wallet {
  .wallet__balance__buttons {
      > a > div {
        padding: 16px;
      }
  }

  .wallet__balance-card {
    padding: 32px 48px;
  }
  
  .wallet__etherium-card {
    padding: 16px 24px;
  }

  .wallet__vaults__content {
    margin-top:16px;
  }

  .wallet-address-card {
    padding: 20px;
    img {
      display: block;
      margin-left: auto;
      margin-right: auto
    }
  }

  .wallet__locker-card {
    padding: 24px;
    padding-top: 48px;
    img {
      width:100%;
      height:100%;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 rgba(0, 0, 0, 0.26);
      border-radius:8px;
    }
  }

  .challenges__content{
    max-height:432px;
    overflow-y:auto;
  }

  &.pub-wallet{
    width:571px;
    margin:120px auto;
    .pub-wallet__balance-card{
      padding: 24px 30px;
    }
  }

  .wallet-level-card {
    padding: 20px 20px 22px 20px;
    .fillbar {
      margin: 6px 0px;
      height:12px;
    }
    .badge {
      margin-top:4px;
      max-width:100%;
      width:120px;
    }
  }
}